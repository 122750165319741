import React, { Component } from 'react';

import Header from '../components/HeaderSection/Header';
import HeroSection from '../components/HeroSection/HeroFive';
import FeatureSection from '../components/Features/FeatureFive';
import ServiceSection from '../components/ServiceSection/ServiceFive';
import DiscoverSection from '../components/DiscoverSection/DiscoverFive';
import PricingSection from '../components/PricingSection/PricingOne';
import Subscribe from '../components/SubscribeSection/Subscribe';
import ContactSection from '../components/ContactSection/Contact';
import FooterSection from '../components/FooterSection/Footer';
import HeroSectionNew from "../components/HeroSection/HeroFiveNew";
import HeroFiveVoice from "../components/HeroSection/HeroFiveVoice";

class Voice extends Component {
    render() {
        return (
            <div className="homepage-5">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <HeroFiveVoice />
                    <PricingSection />
                    <DiscoverSection />
                    <FeatureSection />
                    <ServiceSection />
                    <Subscribe />
                    <ContactSection />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default Voice;
