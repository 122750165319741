import React, { Component } from 'react';

const initData = {
    preHeading: "Premium",
    preHeadingspan: "Features",
    heading: "Express Functionality",
    headingText: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const data = [
    {
        image: "/img/featured_image_1.png",
        title: "Fully functional",
        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    },
    {
        image: "/img/featured_image_2.png",
        title: "Live Chat",
        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    },
    {
        image: "/img/featured_image_3.png",
        title: "Secure Data",
        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    },
    {
        image: "/img/featured_image_4.png",
        title: "Easy to Customize",
        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    },
    {
        image: "/img/featured_image_5.png",
        title: "Responsive Design",
        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    },
    {
        image: "/img/featured_image_6.png",
        title: "Help Documentation",
        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    }
]

class FeatureSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading">
                        <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                            <i className="far fa-lightbulb text-primary mr-1" />
                            <span className="text-primary">{this.state.initData.preHeading}</span>
                            {this.state.initData.preHeadingspan}
                        </span>
                        <h2>Advantages of Spectrum</h2>
                        <p className="d-none d-sm-block mt-4">Spectrum customers can enjoy high-speed Internet with no data caps, channel lineups with free HD and on-demand content, unlimited local and long-distance calls, and much more with Spectrum deals.}</p>
                        <p className="d-block d-sm-none mt-4">Spectrum customers can enjoy high-speed Internet with no data caps, channel lineups with free HD and on-demand content, unlimited local and long-distance calls, and much more with Spectrum deals.</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">Month to Month</h3>
                                        <p>No contracts & month-to-month basis plans, so cancel anytime with no cancellation charge.</p>
                                    </div>
                                    </div>
                                </div>

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">Faster Speeds
                                        </h3>
                                        <p>With Spectrum cable Internet, get faster Internet with download speeds of up to 1 Gbps** (wireless speeds may vary).

                                        </p>
                                    </div>
                                    </div>
                                </div>

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">Truly Unlimited Data
                                        </h3>
                                        <p>Access unlimited data with your Internet plans & stream seamlessly without paying any additional charges.

                                        </p>
                                    </div>
                                    </div>
                                </div>

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">Bundle Options
                                        </h3>
                                        <p>Get the Internet, TV, and phone bundles - everything in one place for a great price.

                                        </p>
                                    </div>
                                    </div>
                                </div>

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">Free Modem with Spectrum Internet
                                        </h3>
                                        <p>The Internet modem is offered for free. That is an additional saving of $5-$10 as customers don’t pay to lease it.

                                        </p>
                                    </div>
                                    </div>
                                </div>

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">30 Days Money Back Guarantee
                                        </h3>
                                        <p>Not satisfied with Spectrum services? Cancel within the first 30 days and get a full refund.

                                        </p>
                                    </div>
                                    </div>
                                </div>

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">WiFi Hotspots
                                        </h3>
                                        <p>Get free unlimited access to nationwide out-of-home WiFi. Sign in once and connect automatically.

                                        </p>
                                    </div>
                                    </div>
                                </div>

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">Extensive Channel Lineup
                                        </h3>
                                        <p>150+ channels through Spectrum TV plans – Including local channels, news, sports, and premium channels (available as add-ons).

                                        </p>
                                    </div>
                                    </div>
                                </div>

                                <div  className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">Free TV App
                                        </h3>
                                        <p>Stream Live TV through Spectrum TV App when you sign up for Spectrum cable TV services.

                                        </p>
                                    </div>
                                    </div>
                                </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;
