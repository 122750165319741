import React, { Component } from 'react';

const initData = {
    heading: "Get Started with Spectrum",
}

class     HeroSection extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section style={{height:'fit-content',minHeight:'100vh'}} id="home" className="section welcome-area bg-overlay d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-lg-6">
                        <div className="welcome-intro">
                        <h1 style={{fontSize:44}} className="text-white">{this.props.title || this.state.data.heading}</h1>
                            <li className="text-white">Surf, stream and do more with up to 300 Mbps download (wireless speeds may vary) </li>
                            <li className="text-white">Enjoy more than 150 of your favorite Spectrum TV channels</li>
                            <li className="text-white">Make unlimited nationwide calls</li>
                            <li className="text-white">Say goodbye to contracts with Spectrum!</li>
                            <h3 style={{marginTop:30}} className="text-white">Spectrum Internet® + TV Select Signature + Voice</h3>
                            <h4 style={{marginTop:10}} className="text-white">{this.props.price || 'From $124.97/mo. for 12 mos* with Auto Pay'}</h4>
                        <div className="button-group store-buttons d-flex">
                                <a href="tel:+18444903356" className="btn sApp-btn text-uppercase">{this.props.price ? "Get Internet":"Get Spectrum Now"}</a>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6">
                            <img src={this.props.bg} alt="image_spectrum"/>
                    </div>
                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path className="shape-fill" fill="#FFFFFF" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div>
            </section>
        );
    }
}

export default HeroSection;
