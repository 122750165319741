import React, { Component } from 'react';
import axios from 'axios';
import image from "./spectrum-home-internet-with-advanced-wiFi.png"

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneServiceSection";

class ServiceSection extends Component {
    state = {
        data: {},
        serviceData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    serviceData: res.data.serviceData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <>

                <section className="watch-tfn-section">
                    <div className="container">
                        <div style={{display:"flex",justifyContent:"center"}} className="row">
                            <div style={{display:"flex",justifyContent:"center"}} className="col-md-12 col-sm-12 col-xs-12">
                                <h2 className="text-white">Get Spectrum Plans. Call Now <span><a style={{color:'#f66200'}}
                                                                                                 href="tel:+18444903356">1-844-490-3356</a></span></h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section service-area overflow-hidden ptb_100">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12 col-lg-6 order-2 order-lg-1">
                                {/* Service Text */}
                                <div className="service-text pt-4 pt-lg-0">
                                    <h2 className="text-capitalize mb-4">Control Your Spectrum Home Internet With Advanced WiFi</h2>
                                    <p>For only $5/month with Spectrum Internet, you can enjoy complete control over your WiFi network with the state-of-the-art Spectrum Advanced WiFi.

                                    </p>
                                    {/* Service List */}
                                    <li style={{marginTop:20}}>The WiFi 6 technology allows for faster and stronger connections
                                       <li>It supports speeds up to 1 Gbps* (wireless speeds may vary)</li>
                                       <li>Its Security Shield offers higher security and privacy</li>
                                       <li>The FREE My Spectrum App allows access from anywhere</li>
                                       <li>Manage parental control settings to keep your children safe online</li>
                                       <li>Get Spectrum WiFi Pods for just $3/month each, if you need to extend your WiFi signal</li>
                                    </li>
                                    <p style={{paddingTop:20}}>* Speed based on wired connection. Available Internet speeds may vary by address. Spectrum Internet Gig with speeds at up to 1 Gig is in addition to the standard monthly Internet price. Gig capable modem required for Gig speed. For a list of Gig capable modems, visit spectrum.net/modem. ©2023 Charter Communications.

                                    </p>
                                    <a href="tel:+18444903356" className="btn btn-bordered mt-4">{this.state.data.buttonText}</a>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 order-1 order-lg-2 d-none d-lg-block">
                                {/* Service Thumb */}
                                <div className="service-thumb mx-auto">
                                    <img src={image} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ServiceSection;
