import React, { Component } from 'react';

const initData = {
    heading: "Get Started with Spectrum",
}

class HeroSectionNew extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <div style={{background:'#175481',paddingTop:'10em'}}>
            <section className="tv-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-7">
                            <div className="banner-txt">
                                <h1><span>Spectrum Cable TV</span> Deals, Packages, and Plans</h1>
                                <h2>Redefine your Cable TV Experience with Spectrum!</h2>
                                <ul>
                                    <li>150+ channels with FREE HD</li>
                                    <li>FREE Spectrum TV App</li>
                                    <li>Thousands of On Demand choices</li>
                                    <li>TV on your terms, the way it’s meant to be</li>
                                </ul>
                                <p className="m-d">Spectrum TV Select Signature</p>
                                <p className="price"><span>From</span> $59.99/mo. <small>For 12 mos*</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        );
    }
}

export default HeroSectionNew;
