import React, { Component } from 'react';
import image from "./spectrum-customer-service.png"

class ContactSection extends Component {

    render() {
        return (
            <section id="contact-phone" className="cs-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5">
                            <div className="banner-txt">
                                <h1 style={{marginBottom:20}} className='text-white'>Contact Spectrum</h1>
                                <ul>
                                    <li>Sign up for Spectrum Internet®, Cable TV, or Home Phone</li>
                                    <li>Quick response to all your queries</li>
                                    <li>Around-the-clock assistance</li>
                                </ul>
                                <h3 style={{margin:'20px 0'}} className="cs-link text-white">Call Now to Order Spectrum Services</h3>
                                <h2 className="tfn-b">
                                    <a className="text-white" href="tel:+18444903356">1-844-490-3356</a>
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 text-center">
                            <img className="img-fluid ls-is-cached lazyloaded" width="480" height="350"
                                 sizes="50vw" alt="Spectrum Customer Service"
                                 src={image} />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;
