import React, { Component } from 'react';

const initData = {
    heading: "Get Started with Spectrum",
}

class HeroFiveVoice extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <div style={{background:'#175481',paddingTop:'10em'}}>
                <section className="phone-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-lg-7">
                                <div className="banner-txt">
                                    <h1><span>Spectrum</span> Voice<sup>®</sup> Plans and Packages</h1>
                                    <h2>Stay Connected with Spectrum Home Phone!</h2>
                                    <ul>
                                        <li>Crystal-clear digital home phone service</li>
                                        <li>Unlimited local and long distance calling to select regions*</li>
                                        <li>28 calling features, including Caller ID and Voicemail</li>
                                        <li>No contracts</li>
                                    </ul>
                                    <h3 className="c-t">SPECTRUM VOICE</h3>
                                    <p className="price mb-0"><span>Only</span> $14.99/mo for 12 mos*<small></small></p>
                                    <p className="jumptag"><a className="mt-1" href="#plans">View
                                        Plans &amp; Pricing</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default HeroFiveVoice;
