import React, { Component } from 'react';

const initData = {
    heading: "Get Started with Spectrum",
}

class HeroFivePackage extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <div style={{background:'#175481',paddingTop:'10em'}}>
                <section className="packages-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-lg-7">
                                <div className="banner-txt">
                                    <h1 className="reduce-h"><span>Spectrum Packages </span> for Internet and TV:
                                        Limitless Entertainment &amp; Connectivity! </h1>
                                    <h2>Shop the best Spectrum package deals.</h2>
                                    <ul>
                                        <li>Download speeds up to 300 Mbps <small>(wireless speeds may vary)</small>
                                        </li>
                                        <li>Free HD channels</li>
                                        <li>No contracts. No hassles. No risks.</li>
                                    </ul>
                                    <h3 className="c-t">Starting from </h3>
                                    <p className="price">$109.98/mo. <small>for 12 mos* with Auto Pay</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default HeroFivePackage;
