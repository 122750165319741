import React, {Component} from 'react';


class Header extends Component {
    render() {
        return (
            <>
                <div className="header_anmimated_parent">
                <div className="header_anmimated">
                    <a href="/">
                    <img src="/img/spectrum_logo.png" alt="spectrum_logo"/>
                    </a>
                    <div>
                        <p>Order Online or Call Now</p>
                        <a id="navbar-tfn" href="tel:+18444903356">
                            <h3 className="header_tfn">
                                1-844-490-3356</h3>
                        </a>
                    </div>
                </div>
                </div>
            <header data-aos="fade-down" className="navbar navbar-sticky navbar-expand-lg navbar-dark">
                <div className="container position-relative">
                    <a className="navbar-brand" href="/">
                        <div className='d-flex justify-content-between align-items-center'>
                            <div style={{display:'flex',flexDirection:'column',color:'#fff'}}>
                            <p className='text-white'>Order Online or Call Now</p>
                            <a id="navbar-tfn"  href="tel:+18444903356">
                                <h3 className="header_tfn text-white">
                                    1-844-490-3356</h3>
                            </a>
                            </div>
                        </div>
                        <img className="navbar-brand-sticky" style={{width: 133,position:'absolute',top:16}} src="/img/spectrum_logo.png"
                             alt="sticky brand-logo"/>
                    </a>
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="navbar-inner">
                        {/*  Mobile Menu Toggler */}
                        <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <nav>
                            <ul className="navbar-nav" id="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/internet">Internet</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Tv
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li>
                                            <a className="dropdown-item" href="/tv">Tv</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/tv">Channel Lineup</a>
                                        </li>

                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/voice">Voice</a>
                                </li>

                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Packages
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li>
                                            <a className="dropdown-item" href="/packages">Packages</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/packages">Spectrum Select Package</a>
                                        </li>

                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" target="_blank" href="https://www.spectrum.com/address/localization">Availability</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" target="_blank" href="https://www.connectyourbusiness.net/providers/spectrum-business?affiliateID=IBEX&tfn=888-330-0891">Business</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/#contact-phone">Phone Number</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </header>
            </>
        );
    }
}

export default Header;
