import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ErrorPage from "../components/ErrorPage/404";
import ThemeFive from "../themes/theme-five";
import Internet from "../themes/internet";
import Tv from "../themes/tv";
import Voice from "../themes/voice";
import Packages from "../themes/packages";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeFive} />
            <Route exact path="/internet" component={Internet} />
            <Route exact path="/tv" component={Tv} />
            <Route exact path="/voice" component={Voice} />
            <Route exact path="/packages" component={Packages} />
            <Route path="*" component={ErrorPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;
