import React, { Component } from 'react';
import axios from 'axios';
import image from "./tv-lap.png"
const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";

class DiscoverSection extends Component {
    state = {
        data: {},
        discoverData: [],
        discoverIcon: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    discoverData: res.data.discoverData,
                    discoverIcon: res.data.discoverIcon
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section discover-area bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Discover Thumb */}
                        <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                            <img src={image} alt="tv-lap" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                        {/* Discover Text */}
                        <div className="discover-text pt-4 pt-lg-0">
                        <h2 className="pb-4 pb-sm-0">Spectrum Contract Buyout – Switching Made Easier</h2>
                        <p className="d-none d-sm-block pt-3 pb-4">If you want to switch to Spectrum but the contract with your current provider is holding you back, do not fret. Spectrum extends a buyout offer to all customers looking to sign up for a qualifying Spectrum Internet and TV package. Spectrum will cover your current provider’s early termination fee up to $500◊ once you sign up.
                        </p>
                        <p className="d-none d-sm-block pt-3 pb-4"> Moreover, since Spectrum knows how annoying contracts can be, they will never bind you in one. Therefore, you are free to stay with Spectrum for as long as you like. That’s how confident they are about their services.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DiscoverSection;
