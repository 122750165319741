import React, { Component } from 'react';
import axios from 'axios';
import laptop from "./laptop.png"
import laptop2 from "./laptop2.png"
import laptop3 from "./laptop3.png"

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOnePricingSection";

class PricingSection extends Component {
    state = {
        data: {},
        pricingList: [],
        pricingData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    pricingList: res.data.pricingList,
                    pricingData: res.data.pricingData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <>
                <section id="pricing" className="section price-plan-area overflow-hidden ptb_100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-7">
                                {/* Section Heading */}
                                <div className="section-heading text-center">
                                    <h2>Get Started with Spectrum</h2>
                                    <p className="d-none d-sm-block mt-4">Spectrum packages offer you access to high-speed Internet for the whole family with a download speed of up to 300 Mbps (wireless speeds may vary).</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10 col-lg-12">
                                <div className="row price-plan-wrapper">
                                    {/*card*/}
                                    <div className="col-12 col-md-4 mt-4">
                                        {/* Single Price Plan */}
                                        <div className="single-price-plan text-center p-5">
                                            {/* Plan Thumb */}
                                            <div className="plan-thumb">
                                                <img className="avatar-lg" src={laptop} alt="" />
                                            </div>
                                            {/* Plan Title */}
                                            <div className="plan-title my-2 my-sm-3">
                                                <h3 className="text-uppercase">Spectrum Internet</h3>
                                            </div>
                                            {/* Plan Price */}
                                            <div className="plan-price pb-2 pb-sm-3">
                                                <h3 className="color-primary"><small className="fw-5">For 12 mos* with Auto Pay </small>$49.99/mo.
                                                </h3>
                                            </div>
                                            {/* Plan Description */}
                                            <div className="plan-description">
                                                <ul className="plan-features">
                                                    <li className="border-top py-3">Up to 300 Mbps Download Speed (wireless speeds may vary)</li>
                                                    <li className="border-top py-3">Unlimited Data with FREE Modem</li>
                                                    <li className="border-top py-3"> Free Antivirus Software</li>
                                                    <li className="border-top border-bottom py-3"> Access to FREE nationwide Spectrum out-of-home WiFi</li>
                                                </ul>
                                            </div>
                                            {/* Plan Button */}
                                            <div className="plan-button">
                                                <a href="tel:+18444903356" className="btn mt-4">Call Now to Order</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mt-4">
                                        {/* Single Price Plan */}
                                        <div className="single-price-plan text-center p-5">
                                            {/* Plan Thumb */}
                                            <div className="plan-thumb">
                                                <img className="avatar-lg"  style={{width:120}} src={laptop2} alt="spectrum" />
                                            </div>
                                            {/* Plan Title */}
                                            <div className="plan-title my-2 my-sm-3">
                                                <h3 className="text-uppercase">Spectrum TV® Select Signature
                                                </h3>
                                            </div>
                                            {/* Plan Price */}
                                            <div className="plan-price pb-2 pb-sm-3">
                                                <h3 className="color-primary"><small className="fw-5">For 12 mos*</small>From $59.99/mo.
                                                </h3>
                                            </div>
                                            {/* Plan Description */}
                                            <div className="plan-description">
                                                <ul className="plan-features">
                                                    <li className="border-top py-3">Over 150 Channels with Free HD
                                                    </li>
                                                    <li className="border-top py-3">On Demand Content</li>
                                                    <li className="border-top py-3">ESPN, Discovery, CNN, Lifetime, TLC, HGTV, & MTV included</li>
                                                    <li className="border-top border-bottom py-3">Free access to Spectrum TV App</li>
                                                </ul>
                                            </div>
                                            {/* Plan Button */}
                                            <div className="plan-button">
                                                <a href="tel:+18444903356" className="btn mt-4">Call Now to Order</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mt-4">
                                        {/* Single Price Plan */}
                                        <div className="single-price-plan text-center p-5">
                                            {/* Plan Thumb */}
                                            <div className="plan-thumb">
                                                <img className="avatar-lg" style={{width:150}} src={laptop3} alt="laptop" />
                                            </div>
                                            {/* Plan Title */}
                                            <div className="plan-title my-2 my-sm-3">
                                                <h3 className="text-uppercase">Spectrum Internet + TV Select Signature + Voice</h3>
                                            </div>
                                            {/* Plan Price */}
                                            <div className="plan-price pb-2 pb-sm-3">
                                                <h3 className="color-primary"><small className="fw-5">For 12 mos* with Auto Pay

                                                </small>From $124.97/mo.
                                                </h3>
                                            </div>
                                            {/* Plan Description */}
                                            <div className="plan-description">
                                                <ul className="plan-features">
                                                    <li className="border-top py-3">ESPN, Discovery, CNN, Lifetime, TLC, HGTV, MTV, & More
                                                    </li>
                                                    <li className="border-top py-3">More than 150 channels with Free TV App</li>
                                                    <li className="border-top py-3">Speeds up to 300 Mbps (wireless speeds may vary) & FREE Modem</li>
                                                    <li className="border-top border-bottom py-3">Unlimited Local & Long Distance Calls</li>
                                                </ul>
                                            </div>
                                            {/* Plan Button */}
                                            <div className="plan-button">
                                                <a href="tel:+18444903356" className="btn mt-4">Call Now to Order</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center pt-5">
                            <p className="text-body pt-4 fw-5">{this.state.data.text} <a href="tel:+18444903356">{this.state.data.textLink}</a></p>
                        </div>
                    </div>
                </section>
                <section className="watch-tfn-section">
                    <div className="container">
                        <div style={{display:"flex",justifyContent:"center"}} className="row">
                            <div style={{display:"flex",justifyContent:"center"}} className="col-md-12 col-sm-12 col-xs-12">
                                <h2 className="text-white">Get Spectrum Plans. Call Now <span><a style={{color:'#f66200'}}
                                    href="tel:+18444903356">1-844-490-3356</a></span></h2>
                            </div>
                        </div>
                    </div>
                </section>
            </>

        );
    }
}

export default PricingSection;
